import React, { useContext, useEffect, useState } from 'react';
import MagneticHover from '../../Components/MagneticHover/MagneticHover';
import { AppContext } from '../../Contexts/AppContext';
import { tryParseJSON } from '../../Common/commonFunction';
import SEO from '../../Common/Components/SEO/SEO';
import './AboutUs.css';

const AboutUs = () => {
    const { aboutUsContent } = useContext(AppContext);
    const [hoveredBox, setHoveredBox] = useState(null);
    const [whatWeOffer, setWhatWeOffer] = useState([]);
    const [whyChooseUs, setWhyChooseUs] = useState(null);
    const [feature, setFeature] = useState([]);

    const defaultContent = {
        title: "We Are Next Aspect Technology",
        description1: "Welcome to NextAspect Technologies Software. a leading software company specializing in SharePoint, PowerApps, Teams, and Office Add-ins development and consulting. With a team of highly skilled professionals, we are committed to delivering innovative solutions that empower organizations to streamline their processes, enhance collaboration, and maximize productivity.",
        description2: ""
    };

    // const whatWeOffer = [
    //     {
    //         key: 'history',
    //         title: "Our History",
    //         description1: "Founded with a vision to transform the way businesses operate, NextAspect Technologies began as a small, ambitious startup with a focus on innovative software development. Our journey started by offering specialized services in SharePoint and Office 365 tools, quickly expanding our expertise to include PowerApps, Teams, and Office Add-ins.",
    //         description2: "Today, NextAspect Technologies continues to innovate and lead in the development and consulting space, providing cutting-edge solutions that enable organizations to thrive in the modern digital landscape.",
    //         image: 'History'
    //     },
    //     {
    //         key: 'vision',
    //         title: "Our Vision",
    //         description1: "At NextAspect Technologies, we envision a future where organizations thrive in a dynamic and interconnected digital landscape. Our goal is to be a global leader in software development and consulting, specializing in Microsoft technologies like SharePoint, PowerApps, Teams, and Office Add-ins",
    //         description2: "We aim to empower businesses by providing innovative solutions that transform the way they operate. By leveraging advanced technology and our expertise, we aspire to simplify complex processes, foster collaboration, and enhance productivity for teams worldwide.",
    //         image: 'Vision'
    //     },
    //     {
    //         key: 'mission',
    //         title: "Our Mission",
    //         description1: "Our mission is to empower businesses with cutting-edge technology solutions that drive growth and success.",
    //         description2: "We are committed to delivering outstanding results through our expertise, dedication, and innovation.",
    //         image: 'Mission'
    //     },
    //     {
    //         key: 'strategy',
    //         title: "Our Strategy",
    //         description1: "Our strategy is to combine deep industry knowledge with advanced technical skills to create custom solutions tailored to our clients' needs.",
    //         description2: "We focus on building long-term partnerships based on trust, transparency, and mutual success.",
    //         image: 'Strategy'
    //     }
    // ];

    // [
    //     {
    //         "key": "history",
    //         "title": "Our History",
    //         "description1": "Founded with a vision to transform the way businesses operate, NextAspect Technologies began as a small, ambitious startup with a focus on innovative software development. Our journey started by offering specialized services in SharePoint and Office 365 tools, quickly expanding our expertise to include PowerApps, Teams, and Office Add-ins.",
    //         "description2": "Today, NextAspect Technologies continues to innovate and lead in the development and consulting space, providing cutting-edge solutions that enable organizations to thrive in the modern digital landscape.",
    //         "image": "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/About%20Us%2FHistory.png?alt=media&token=d070aa41-7c84-4f80-9e92-d3dfe1b3b3b9"
    //     },
    //     {
    //         "key": "vision",
    //         "title": "Our Vision",
    //         "description1": "At NextAspect Technologies, we envision a future where organizations thrive in a dynamic and interconnected digital landscape. Our goal is to be a global leader in software development and consulting, specializing in Microsoft technologies like SharePoint, PowerApps, Teams, and Office Add-ins",
    //         "description2": "We aim to empower businesses by providing innovative solutions that transform the way they operate. By leveraging advanced technology and our expertise, we aspire to simplify complex processes, foster collaboration, and enhance productivity for teams worldwide.",
    //         "image": "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/About%20Us%2FVision.png?alt=media&token=4e133214-9fd6-4031-94c4-c64f5009ce62"
    //     },
    //     {
    //         "key": "mission",
    //         "title": "Our Mission",
    //         "description1": "Our mission is to empower businesses with cutting-edge technology solutions that drive growth and success.",
    //         "description2": "We are committed to delivering outstanding results through our expertise, dedication, and innovation.",
    //         "image": "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/About%20Us%2FMission.png?alt=media&token=c1b3ae1a-dfe7-4ca9-b208-79c30f7d19cd"
    //     },
    //     {
    //         "key": "strategy",
    //         "title": "Our Strategy",
    //         "description1": "Our strategy is to combine deep industry knowledge with advanced technical skills to create custom solutions tailored to our clients' needs.",
    //         "description2": "We focus on building long-term partnerships based on trust, transparency, and mutual success.",
    //         "image": "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/About%20Us%2FStrategy.png?alt=media&token=8e124c7a-a05b-41d3-bb1c-1cee79ec030d"
    //     }
    // ]

    useEffect(() => {
        if (aboutUsContent && aboutUsContent.length > 0) {
            const parsedWhatWeOffer = tryParseJSON(aboutUsContent[0].WhatWeOffer);
            const parsedWhyChooseUs = tryParseJSON(aboutUsContent[0].WhyChooseUs);
            const parsedFeature = tryParseJSON(aboutUsContent[0].Feature);

            if (parsedWhatWeOffer) {
                setWhatWeOffer(parsedWhatWeOffer);
            } else {
                setWhatWeOffer([]);
            }

            if (parsedWhyChooseUs) {
                setWhyChooseUs(parsedWhyChooseUs);
            } else {
                setWhyChooseUs({
                    title: "We believe in people and technology.",
                    subTitle: "Leading IT Company with Innovative Products and Services",
                    description: "NextAspect Technologies stands at the cutting edge of innovation. Our vibrant team of professionals and industry experts is dedicated to developing revolutionary software products and IT services. We’re committed to redefining the tech landscape with solutions that drive progress and deliver exceptional value.",
                    image: "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/About%20Us%2Fchoose-img-1.png?alt=media&token=b73f2508-5f7b-4ddd-aed0-6dd82c022d56",
                    appLogo: "https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/App%20Logo%2FFinalLogo.png?alt=media&token=7b6d1f40-8fdd-40b1-b12f-d220fc47cab0"
                });
            }

            if (parsedFeature) {
                setFeature(parsedFeature);
            } else {
                setFeature([]);
            }
        }
    }, [aboutUsContent]);

    const currentContent = hoveredBox ? whatWeOffer?.find(item => item.key === hoveredBox) : defaultContent;

    return (
        <div>
            <SEO
                title="About Us | NextAspect Technologies - Company Overview"
                description="Discover NextAspect Technologies, a leader in SharePoint consulting, web development, and enterprise IT services, helping businesses innovate, scale, and thrive."
                keywords="NextAspect Technologies, About NextAspect, Company Overview, SharePoint Consulting, Web Development, IT Services, Custom Enterprise Solutions"
                canonical="https://nextaspecttech.com/about-us"
            />
            <section className="about-offer">
                <div className="container">
                    <div className="grid flex-row-reverse">
                        <div className="col-12 align-self-center lg:col-6 md:p-6">
                            <div className="ot-heading">
                                <span style={{ color: '#f5bb0c' }}>// what we offer</span>
                                <h2 className="main-heading mb-3 text-white">{hoveredBox ? currentContent?.title : defaultContent.title}</h2>
                            </div>
                            <div className="space-5"></div>
                            <p className='text-white'>{hoveredBox ? currentContent?.description1 : defaultContent?.description1}</p>
                            <p><em className="text-white"><strong>{hoveredBox ? currentContent?.description2 : defaultContent?.description2}</strong></em></p>
                            <div className="space-20"></div>
                            {/* <div className="video-popup style-2">
                                <div className="btn-inner">
                                    <a className="btn-play" href="https://www.youtube.com/watch?v=lfDZJqSrIuk">
                                        <i className="pi pi-play-circle"></i>
                                        <span className="circle-1"></span>
                                        <span className="circle-2"></span>
                                    </a>
                                </div>
                                <span style={{ color: '#f5bb0c' }}>video showcase</span>
                            </div> */}
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="grid md:px-8 md:pt-6">
                                {whatWeOffer && whatWeOffer.length > 0 && whatWeOffer.map((ele, index) => {
                                    return (
                                        <div className="col-12 lg:col-6 md:col-6" key={index}>
                                            <a className={`ot-image-box v3 ${index % 2 == 0 ? 'st3' : 'st4'} mb-0`}
                                                href="#"
                                                onMouseEnter={() => setHoveredBox(ele.key)}
                                                onMouseLeave={() => setHoveredBox(null)}>
                                                <div className="overlay">
                                                    <h4>{ele.title}</h4>
                                                </div>
                                                <img src={ele.image} alt="Our Strategy" />
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="home6-choose-section sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="choose-top">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="choose-title">
                                        <span>Why Choose Us</span>
                                        <h2>{whyChooseUs?.title}</h2>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="choose-right-img">
                                        <MagneticHover>
                                            <img className="img-fluid " src={whyChooseUs?.image} alt="" />
                                        </MagneticHover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="choose-btm">
                            <div className="row g-lg-4 gy-5">
                                <div className="col-lg-7">
                                    <div className="choose-left-content">
                                        <div className="vectors">
                                            <img className="choose-vec-top-r" src={require('../../Assets/images/choose-vec-top-r.svg').default} alt="" />
                                            <img className="choose-vec-btm-l" src={require('../../Assets/images/choose-vec-btm-l.svg').default} alt="" />
                                        </div>
                                        <div className="icon">
                                            <img src={whyChooseUs?.appLogo} alt="" />
                                        </div>
                                        <h4>{whyChooseUs?.subTitle}<br />
                                            {/* <span>Since 2005.</span> */}
                                        </h4>
                                        <p>{whyChooseUs?.description}</p>
                                        <div className="sl">
                                            <h2>#1</h2>
                                        </div>
                                        {/* <div className="about-btn">
                                            <a href="about.html">
                                                <svg width="7" height="7" viewBox="0 0 13 13" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                </svg>
                                                About More
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="choose-feature">
                                        <ul className='list-none p-0'>
                                            {feature && feature.length > 0 && feature.map((ele, index) => {
                                                return (
                                                    <li className='mb-3' key={index}>
                                                        <div className="single-feature">
                                                            <div className="progress">
                                                                <img className='w-7' src={require(`../../Assets/images/AboutUs/${ele.icon}.png`)} alt="" />

                                                            </div>
                                                            <div className="content">
                                                                <h4>{ele.title}</h4>
                                                                <p>{ele.description}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })};
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;