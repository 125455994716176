import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        smoothScrollToTop();
    }, [pathname]);

    return null;
};

const smoothScrollToTop = () => {
    const scrollStep = -window.scrollY / (10 / 15);
    const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
            window.scrollBy(0, scrollStep);
        } else {
            clearInterval(scrollInterval);
        }
    }, 15);
};

export default ScrollToTop;