import React from 'react';
import SEO from '../../Common/Components/SEO/SEO';
import './Home.css';

const Home = () => {
    return (
        <>
            <SEO
                title="SharePoint Consulting & Enterprise Products Development Company | NextAspect Technologies"
                description="NextAspect Technologies offers web development, SharePoint solutions, and custom IT services tailored to your business needs."
                canonical="https://nextaspecttech.com/"
            />
            <div className="home-container">
                <video autoPlay loop muted className="background-video">
                    <source src={require('../../Assets/videos/Main.mp4')} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="centered-text" style={{ color: '#fff' }}>
                    {/* <h1>Welcome to Our Website</h1> */}
                    <p>From Vision to Virtual Reality</p>
                </div>
            </div>
        </>
    );
};

export default Home;