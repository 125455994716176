import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer className="help-center-footer">
            <p className='mb-0 font-medium'>
                Copyright © 2024 By <a href="https://nextaspecttech.com/" target='_blank'>NextAspect Technologies</a>. All Rights Reserved.
            </p>
        </footer>
    );
};

export default Footer;
