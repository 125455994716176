import React, { useContext } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import { toSlug } from '../../Common/commonFunction';
import { Link } from 'react-router-dom';
import SEO from '../../Common/Components/SEO/SEO';
import './Services.css';

const Services = () => {
    const { servicesContent } = useContext(AppContext);

    return (
        <div className='bg-color'>
            <SEO
                title="Our Services | NextAspect Technologies - Software Development Services"
                description="Explore NextAspect Technologies wide range of services, including SharePoint consulting, web development, custom IT services, and enterprise solutions for your business."
                keywords="NextAspect Technologies Services, SharePoint Consulting, Web Development, IT Services, Business Automation, Custom Enterprise Solutions, NextAspect"
                canonical="https://nextaspecttech.com/services"
            />
            <div className="home6-solution-section container">
                <div className="grid">
                    <div className='col-12 flex justify-content-center'>
                        <h2 className='text-white pt-5'>We Offer a Wide Variety of IT Services</h2>
                    </div>
                    {servicesContent && servicesContent.length > 0 && servicesContent.map((ele, index) => {
                        return (
                            <div className="col-12 md:col-4 sm:col-10" key={index}>
                                <div className="single-solution" data-aos="zoom-in">
                                    <div className="background-img">
                                        <img src={ele.BackgroundImage} alt="" />
                                    </div>
                                    <div className="sl">
                                        <h2 className='m-0'>0{index + 1}</h2>
                                    </div>
                                    <div className="solution-content" style={{ height: '13rem' }}>
                                        <h3><Link className='service-title' to={`/service-details/${toSlug(ele.Title)}`}>{ele.Title}</Link></h3>
                                        <p>{ele.Description}</p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <Link to={`/service-details/${toSlug(ele.Title)}`} className="primary-btn9">
                                                <span>{ele.ButtonLabel}</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L1.30274e-07 7.5" />
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="icon">
                                            <svg className="blure" width="46" height="46" viewBox="0 0 122 122" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.7" filter="url(#filter0_f_1886_2043)">
                                                    <circle cx="61" cy="61" r="23" fill="url(#paint0_linear_1886_2043)"></circle>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_f_1886_2043" x="0" y="0" width="122" height="122"
                                                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                                                            result="shape"></feBlend>
                                                        <feGaussianBlur stdDeviation="19" result="effect1_foregroundBlur_1886_204">
                                                        </feGaussianBlur>
                                                    </filter>
                                                    <linearGradient id="paint0_linear_1886_2043" x1="61" y1="38" x2="61" y2="84"
                                                        gradientUnits="userSpaceOnUse">
                                                        <stop offset="0" stopColor="#f5bb0c " stopOpacity="0"></stop>
                                                        <stop offset="1" stopColor="#f5bb0c "></stop>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <img src={ele.Icon} alt='WebDevelopment' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Services;