import React, { useEffect } from 'react';
import AppContextProvider from './Contexts/AppContext';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import AboutUs from './Pages/AboutUs/AboutUs';
import ParallaxSection from './Components/ParallaxSection/ParallaxSection';
import Sitemap from './Pages/Sitemap/Sitemap';
import AnimatedCursor from "react-animated-cursor"
import AOS from 'aos';
import ScrollToTop1 from './Components/ScrollToTop/ScrollToTop';
import ScrollToTop from "react-scroll-to-top";
import ServiceDetails from './Pages/Services/ServiceDetails';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Products from './Pages/Products/Products';
import ProductDetails from './Pages/Products/ProductDetails';
import FAQs from './Pages/FAQs/FAQs';
import Sections from './Pages/Sections/Sections';
import HelpCenter from './Pages/HelpCenter/HelpCenter';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import ContactUs from './Pages/ContactUs/ContactUs';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import CookieConsent from './Components/CookieConsent/CookieConsent';
import { GOOGLE_RE_CAPTCHA_V3_KEY } from './Common/constant';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './App.css';

const App = () => {

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  return (
    <AppContextProvider>
      <div className="App">
        {/* <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color='245,187,12'
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={1.4}
      /> */}
        <ScrollToTop
          smooth
          component={<i className="pi pi-angle-double-up" style={{ color: '#f5bb0c', fontSize: '1.5rem' }}></i>}
        />
        <Router>
          <ScrollToTop1 />
          <MainRoutes />
        </Router>
      </div>
    </AppContextProvider>
  );
}


const MainRoutes = () => {
  const location = useLocation();

  const isHelpCenterRoute = location.pathname.startsWith('/help-center');

  return (
    <>
      <CookieConsent />
      {!isHelpCenterRoute && <Header />}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/sitemap" exact element={<Sitemap />} />
        <Route path="/contact-us" exact element={<GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RE_CAPTCHA_V3_KEY} ><ContactUs /></GoogleReCaptchaProvider>} />
        <Route path="/services" exact element={<Services />} />
        <Route path="/service-details/:id" exact element={<ServiceDetails />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/products/:id" exact element={<ProductDetails />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" exact element={<TermsAndConditions />} />
        <Route path="/faqs" exact element={<FAQs />} />
        <Route path="/help-center/*" element={<HelpCenter />} />
        <Route path="/section" exact element={<Sections />} />
      </Routes>
      {!isHelpCenterRoute && <Footer />}
      {/* <div>
          <ParallaxSection
            image="https://images.unsplash.com/photo-1540202404-d0c7fe46a087?q=80&w=1933&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            title="Welcome to Our Software Company"
            subtitle="We create innovative software solutions"
          />
          <Services />
          <ParallaxSection
            image="https://images.pexels.com/photos/1420702/pexels-photo-1420702.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            title="Our Mission"
            subtitle="To deliver high-quality software solutions"
          />
          <AboutUs />
          <ParallaxSection
            image="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            title="Get in Touch"
            subtitle="Contact us for more information"
          />
          <ContactUs />
        </div> */}
    </>
  );
};

export default App;