import React from 'react';
import './Sitemap.css';

const Sitemap = () => {
    return (
        <div className="sitemap">
            <header className="sitemap-header">
                <h1>Sitemap</h1>
            </header>
            <div className="sitemap-content">
                <div className="sitemap-section">
                    <h2>Main Sections</h2>
                    <ul>
                        <li><i className="pi pi-home sitemap-icon"></i><a href="/">Home</a></li>
                        <li><i className="pi pi-info-circle sitemap-icon"></i><a href="/about">About Us</a></li>
                        <li><i className="pi pi-cogs sitemap-icon"></i><a href="/services">Services</a></li>
                        <li><i className="pi pi-project-diagram sitemap-icon"></i><a href="/projects">Projects</a></li>
                        <li><i className="pi pi-phone sitemap-icon"></i><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className="sitemap-section">
                    <h2>IT Solutions</h2>
                    <ul>
                        <li><i className="pi pi-lock sitemap-icon"></i><a href="/it-management">IT Management</a></li>
                        <li><i className="pi pi-shield sitemap-icon"></i><a href="/seo-optimization">SEO Optimization</a></li>
                        <li><i className="pi pi-tools sitemap-icon"></i><a href="/web-development">Web Development</a></li>
                        <li><i className="pi pi-server sitemap-icon"></i><a href="/cyber-security">Cyber Security</a></li>
                        <li><i className="pi pi-user-shield sitemap-icon"></i><a href="/data-security">Data Security</a></li>
                    </ul>
                </div>
                <div className="sitemap-section">
                    <h2>Resources</h2>
                    <ul>
                        <li><i className="pi pi-blog sitemap-icon"></i><a href="/blog">Blog</a></li>
                        <li><i className="pi pi-question-circle sitemap-icon"></i><a href="/faq">FAQ</a></li>
                        <li><i className="pi pi-file sitemap-icon"></i><a href="/terms">Terms & Conditions</a></li>
                        <li><i className="pi pi-file sitemap-icon"></i><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sitemap;