import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import { Link } from 'react-router-dom';
import { toSlug } from '../../Common/commonFunction';
import './Header.css';

const Header = () => {
    const { servicesContent, productsContent } = useContext(AppContext);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isSticky, setSticky] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const currentLocation = window.location.href;
        const menuItems = document.querySelectorAll('ul li a');
        menuItems.forEach((item) => {
            if (item.href === currentLocation) {
                item.classList.add('active');
            }
        });
        const sidebarButton = document.querySelector('.sidebar-button');
        if (sidebarButton) {
            sidebarButton.addEventListener('click', () => document.querySelector('.main-menu').classList.toggle('show-menu'));
        }
        return () => {
            if (sidebarButton) {
                sidebarButton.removeEventListener('click', () => document.querySelector('.main-menu').classList.toggle('show-menu'));
            }
        };
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const toggleSubMenu = (menu) => {
        setSubMenuOpen((prevSubMenuOpen) => ({
            ...prevSubMenuOpen,
            [menu]: !prevSubMenuOpen[menu],
        }));
    };

    return (
        <header className={`header-area2 ${isSticky ? 'sticky' : ''}`}>
            <div className="header-logo">
                <Link to='/'><img alt="image" className="img-fluid" src={require('../../Assets/images/FinalLogo.png')} /></Link>
            </div>
            <div className="main-menu">
                <div className="mobile-logo-area d-lg-none">
                    <div className="mobile-logo-wrap">
                        <Link to='/'><img alt="image" src={require('../../Assets/images/FinalLogo.png')} /></Link>
                    </div>
                </div>
                <ul className="menu-list">
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/about-us'>About Us</Link></li>
                    <li className="menu-item-has-children"
                        onMouseEnter={() => setActiveDropdown('products')}
                        onMouseLeave={() => setActiveDropdown(null)}>
                        <Link to="/products" className="drop-down">Products</Link>
                        <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('products')}></i>
                        <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['products'] : activeDropdown === 'products') ? 'block' : 'none' }}>
                            {productsContent && productsContent.length > 0 && productsContent.map((ele, index) => {
                                return (
                                    <li key={index}><Link to={`/products/${toSlug(ele.ProductName)}`}>{ele.ProductName}</Link></li>
                                )
                            })}
                        </ul>
                    </li>
                    <li className="menu-item-has-children"
                        onMouseEnter={() => setActiveDropdown('services')}
                        onMouseLeave={() => setActiveDropdown(null)}>
                        <Link to='/services'>Services</Link>
                        <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('services')}></i>
                        <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['services'] : activeDropdown === 'services') ? 'block' : 'none' }}>
                            {servicesContent && servicesContent.length > 0 && servicesContent.map((ele, index) => {
                                return (
                                    <li key={index}><Link to={`/service-details/${toSlug(ele.Title)}`}>{ele.Title}</Link></li>
                                )
                            })}
                        </ul>
                    </li>
                    <li><Link to='/faqs'>FAQs</Link></li>
                    {/* <li className="menu-item-has-children"
                            onMouseEnter={() => setActiveDropdown('pages')}
                            onMouseLeave={() => setActiveDropdown(null)}>
                            <a href="#" className="drop-down">Pages</a>
                            <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('pages')}></i>
                            <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['pages'] : activeDropdown === 'pages') ? 'block' : 'none' }}>
                                <li>
                                    <a href="team1.html">Team</a>
                                    <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('team')}></i>
                                    <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['team'] : activeDropdown === 'team') ? 'block' : 'none' }}>
                                        <li><a href="team1.html">Team 01</a></li>
                                        <li><a href="team2.html">Team 02</a></li>
                                        <li><a href="team3.html">Team 03</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="pricing.html">Pricing</a>
                                    <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('pricing')}></i>
                                    <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['pricing'] : activeDropdown === 'pricing') ? 'block' : 'none' }}>
                                        <li><a href="pricing.html">Pricing 01</a></li>
                                        <li><a href="pricing2.html">Pricing 02</a></li>
                                    </ul>
                                </li>
                                <li><a href="contact.html">Contact</a></li>
                                <li><a href="faq.html">FAQs</a></li>
                                <li><a href="404.html">404</a></li>
                            </ul>
                        </li> */}
                    {/* <li><Link to='#'>Support</Link></li> */}
                    <li><Link to='/contact-us'>Contact Us</Link></li>
                    {/* <li className="menu-item-has-children"
                            onMouseEnter={() => setActiveDropdown('blog')}
                            onMouseLeave={() => setActiveDropdown(null)}>
                            <a href="#" className="drop-down">Blog</a>
                            <i className="pi pi-plus dropdown-icon" onClick={() => toggleSubMenu('blog')}></i>
                            <ul className="sub-menu" style={{ display: (isMobile ? subMenuOpen['blog'] : activeDropdown === 'blog') ? 'block' : 'none' }}>
                                <li><a href="blog.html">Blog</a></li>
                                <li><a href="blog-details.html">Blog Details</a></li>
                            </ul>
                        </li> */}
                </ul>
            </div>
            <div className="nav-right flex justify-content-end align-items-center">
                <div className="sidebar-btn2">
                    <img src={require('../../Assets/images/sidebar-btn.svg').default} alt="" />
                </div>
                <div className="header-btn d-xl-flex d-none">
                    <Link className="primary-btn3" to="/contact-us">Get A Quote</Link>
                </div>
                <div className="sidebar-button mobile-menu-btn" onClick={toggleSidebar}>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default Header;